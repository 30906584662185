import { menuAnatomy } from '@chakra-ui/anatomy'
import { createMultiStyleConfigHelpers } from '@chakra-ui/react'
import { green } from 'theme/colors'

// Create helpers for defining styles
const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
    menuAnatomy.keys,
)

// Define the base component styles
const variants = {
    primary: definePartsStyle({
        list: {
            bgColor: 'beige.highlighted',
            border: 'none',
            boxShadow: '0px 2px 10px 1px ' + green.transparent_10,
            minW: { base: '10rem', sm: '14rem' },
        },
        item: {
            bgColor: 'beige.highlighted',
            py: ['4px', '6px', '8px'],
            px: ['10px', '16px', '20px'],
            transition: 'background .25s',
            _hover: {
                bgColor: 'beige.darkened',
            },
        },
    }),
}

// Export the base styles in the component theme
export const Menu = defineMultiStyleConfig({ variants })
