import { ComponentStyleConfig, defineStyle } from '@chakra-ui/react'

const variants = {
    complianceChecklistBanner: defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            base: {
                bgColor: colors.yellow[100],
            },
            icon: {
                color: colors.yellow[150],
            },
        }
    }),

    repaymentAlertBanner: defineStyle(({ theme }) => {
        const colors = theme.colors

        return {
            base: {
                bgColor: colors.orange[80],
            },
            icon: {
                color: colors.orange[150],
            },
        }
    }),
}

export const Banner: ComponentStyleConfig = {
    baseStyle: { base: { display: 'flex' } },
    variants,
    defaultProps: {
        variant: 'complianceChecklistBanner',
    },
}
